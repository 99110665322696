import { FC, ReactNode, useCallback } from 'react';
import { useIntercom } from 'react-use-intercom';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { useConfig } from '@shared/contexts/ConfigContext';

import { styles } from './SideNavigationIntercomAction.styles';

export interface SideNavigationIntercomActionProps extends WithStyles<typeof styles> {
  label: string;
  startIcon: ReactNode;
}

const SideNavigationIntercomActionComponent: FC<SideNavigationIntercomActionProps> = ({
  classes,
  label,
  startIcon,
}) => {
  const { enquireUrl, intercomEnabled } = useConfig();
  const { show } = useIntercom();

  const openIntercom = useCallback(() => {
    if (intercomEnabled) {
      show();

      return;
    }

    window.open(enquireUrl, '_blank');
  }, [intercomEnabled, enquireUrl, show]);

  return (
    <div className={classes.root} onClick={openIntercom}>
      <span className={classes.icon}>{startIcon}</span>
      <span className={classes.actionLabel}>{label}</span>
    </div>
  );
};
export const SideNavigationIntercomAction = withStyles(styles)(SideNavigationIntercomActionComponent);
